import { Vector2 } from '../../../utils/vector2';
import { IdleNPC } from './idleNPC';

export class NPCWoman extends IdleNPC {
    constructor({
        scale = 5,
        position = Vector2.zero,
        direction = -1
    }: {
        scale?: number;
        position?: Vector2;
        direction?: number
    } = {}) {
        super({
            scale,
            position,
            size: new Vector2(37, 46),
            idle: '/img/spritesheets/woman-idle.png',
            idleCount: 7,
            idleInterval: 6,
            direction: direction,
        });
    }
}