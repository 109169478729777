import { CanvasSquare } from '../elements/canvasSquare';
import { CanvasWrapper, CanvasWrapperAttributes } from '../elements/canvasWrapper';
import { Collider } from './collider';
import { ElementRelativity } from './elementPosition';
import { Vector2 } from "./vector2";

export type levelAttributes =  CanvasWrapperAttributes & {

}
export abstract class Level extends CanvasWrapper{
    abstract start: Vector2;
    abstract background: CanvasSquare;
    public relativity: ElementRelativity = 'anchor';
    public ready = false;
    public colliders: Collider[] = [];
    public get center(): Vector2 {
        // console.log(this.mode.size.scale(0.5).subtract(this.position).x);
        
        return this.mode.size.scale(0.5).subtract(this.position);
    }

    constructor(attr: levelAttributes = {}) {
        super(attr);
        this.level = this;
        this.mode = this.mode;
        this.size = this.size;
    }

}
